
module.exports =
  metaInfo:
    title: 'Дети: экономия на игрушках, вещах, развлечениях и излишествах'
    meta: [
      { name: 'description', content: 'Полезная, увлекательная и умная экономия на детях: 90 эффективных способов делать ребёнка счастливым без лишних трат и ненужных покупок. Эксклюзивно от ХаниМани!' }
      { name: 'keywords', content: 'экономия на детях игрушках активностях кружках детской одежде' }
      { name: 'human_title', content: 'Дети: экономия на игрушках, вещах, развлечениях и излишествах' }
      { name: 'human_subtitle', content: 'Ещё больше счастья за меньшие деньги' }
    ]
